<card-layout
  [title]="item?.title"
  [description]="item?.content"
  [cta]="'general.details' | translate"
  [chip]="
    (item?.itemState === 'overtime' ? 'event.inOt' : 'event.auction')
      | translate
  "
  [image]="image"
  [path]="['item', item?.id || '']"
  [titleNote]="'event.bidsTT' | translate : { bids: item?.bidCount || 0 }"
  (clicked)="onClick()"
  [teamName]="item?.foundation?.name"
  [overlayImage]="overlayImage"
>
  <card-auction-detail [item]="item"></card-auction-detail>
  <ng-content></ng-content>
</card-layout>
