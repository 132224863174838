import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GlobalState } from '../../../store';
import { sendProfile } from '../../../store/user/user.actions';
import {
  selectUserProcessing,
  selectUserState,
} from '../../../store/user/user.selectors';
import { ProfileAddress, UserProfile } from '../../../types';

@Component({
  selector: 'shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss'],
})
export class ShippingFormComponent implements OnDestroy {
  profile?: UserProfile;
  processing$ = this.store.select(selectUserProcessing);
  subs?: Subscription;
  profileAddress: ProfileAddress = {
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    default: false
  };
  saveButtonText: string = '';

  @Input() title: string | undefined;
  _section: string | undefined; // oboarding | new | edit | new-item-section
  @Input() set section(value: string) {
    this._section = value;
    if (this._section === 'onboarding') {
      this.saveButtonText = 'account.update';
    } else if (this._section === 'new') {
      this.saveButtonText = 'shipping.addNew';
    } else if (this._section === 'new-item-section') {
      this.saveButtonText = 'general.confirm';
    } else {
      this.saveButtonText = 'shipping.updateInfo';
    }
  }
  _addressIndex: number | undefined;
  @Input() set addressIndex(value: number | undefined) {
      this._addressIndex = value;
      // Edit existing address from profile addresses
      if (value !== undefined && this.profile?.addresses?.[value]) {
        this.profileAddress = {...this.profile.addresses[value]};
      }
  }
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<void>();




  constructor(private store: Store<GlobalState>) {
    this.subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) this.profile = { ...profile };
    });
  }

  submit() {
    let newAddresses = [];
    if (this._section === 'edit' && this._addressIndex !== undefined) {
      newAddresses = [...(this.profile?.addresses ?? [])];
      newAddresses.splice(this._addressIndex, 1, {...this.profileAddress});
    } else if (this._section === 'new' || this._section === 'new-item-section') {
      newAddresses = [...(this.profile?.addresses ?? []), {...this.profileAddress}];
    } else {
      newAddresses = [{...this.profileAddress}];
    }
    this.store.dispatch(
      sendProfile({
        profile: {
          addresses: newAddresses
        },
      })
    );
    this.onSave.emit();
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }
}
