import { Bid } from '@fan-types/bid';
import { AuctionItem } from '@fan-types/item.model';
import { PurchaseItem } from '@fan-types/purchase-item';
import { ApiBase } from '@fan-services/models/api-base';
import { FulfillmentOption } from '@fan-types/fulfillment-option';
import { ProfileAddress } from '@fan-types/user-profile';

export class AuctionItemApi extends ApiBase {
  model = 'auction-items';

  bid(id: string, bid: Bid) {
    return this.http.post<AuctionItem>(this.modelUri(id, 'bid'), bid);
  }

  enter(
    itemId: string,
    body: {
      profileId?: string;
      contactNumber?: string;
      email?: string;
      firstName?: string;
      lastName?: string;
      shipAddress1?: string;
      shipAddress2?: string;
      shipCity?: string;
      shipState?: string;
      shipZip?: string;
      location?: any;
    }
  ) {
    return this.http.post<AuctionItem>(this.modelUri(itemId, 'enter'), body);
  }

  purchaseItem(itemId: string, params: PurchaseItem) {
    return this.http.post<AuctionItem>(
      this.modelUri(itemId, 'purchase'),
      params
    );
  }

  otBid(itemId: string, bid: Bid) {
    return this.http.post<AuctionItem>(
      this.modelUri(itemId, 'overtime-bid'),
      bid
    );
  }

  getAuctionItemOtById(itemId: string) {
    return this.http.get<AuctionItem>(this.modelUri(itemId, 'overtime'));
  }

  updatePaymentMethod(itemId: string, cardId: string) {
    return this.http.put<void>(this.modelUri(itemId, 'payment-method'), {
      cardId,
    });
  }

  findById(id: string) {
    return this.http.get<AuctionItem>(this.modelUri(id));
  }

  related(id: string) {
    return this.http.get<AuctionItem[]>(this.modelUri(id, 'related'));
  }

  updateFulfillmentMethod(id: string, fulfillmentOption: Partial<FulfillmentOption>, shipAddress?: ProfileAddress) {
    return this.http.post<{updated: boolean}>(this.modelUri(id, 'fulfillment-option'), {
      fulfillmentOption,
      shipAddress
    });
  }
}
