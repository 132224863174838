import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, withLatestFrom } from 'rxjs/operators';

import * as ItemActions from './item.actions';
import { ApiService } from '../../services/api.service';
import {
  endAuction,
  startAuction,
  upsertAuction,
} from '../auction/auction.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { selectAll } from './item.reducer';
import { GlobalState } from '../state';
import { Store } from '@ngrx/store';
import { ItemState } from '../../types';

@Injectable()
export class ItemEffects {
  loadItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ItemActions.pullItem),
      mergeMap(({ itemId }) => {
        return this.api.auctionItem.findById(itemId).pipe(
          mergeMap((item) => {
            const res: TypedAction<any>[] = [ItemActions.upsertItem({ item })];
            if (item.auction)
              res.push(upsertAuction({ auction: item.auction }));
            return res;
          })
          //         catchError(error => of(AuthApiActions.loginFailure({ error })))
        );
      })
    );
  });

  startAuction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startAuction),
      withLatestFrom(this.store.select(selectAll)),
      mergeMap(([{ auctionId }, items]) => {
        const eventItems = items.filter((i) => i.auctionId === auctionId);
        return eventItems.map((i) =>
          ItemActions.updateItem({
            item: { id: i.id, changes: { itemState: ItemState.Open } },
          })
        );
      })
    );
  });

  endAuction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(endAuction),
      withLatestFrom(this.store.select(selectAll)),
      mergeMap(([{ auctionId }, items]) => {
        const eventItems = items.filter((i) => i.auctionId === auctionId && i.itemState === ItemState.Open);
        return eventItems.map((i) =>
          ItemActions.updateItem({
            item: { id: i.id, changes: { itemState: ItemState.Closing } },
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private readonly api: ApiService,
    private store: Store<GlobalState>
  ) {}
}
