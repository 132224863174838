<section class="fulfillment-option-section" *ngIf="fulfillmentOptions.length">
  <!-- Section to select fulfillment option and address if option is shipping -->
  <div *ngIf="!showNewAddressComponent">
    <div class="column-container">
      <mat-form-field appearance="outline">
        <mat-label>{{'shipping.fulfillmentMethod' | translate}}</mat-label>
        <mat-select #optionSelector="ngModel" (ngModelChange)="onOptionSelected($event)" [(ngModel)]="fulfillmentOption" [compareWith]="objectComparisonFunction">
          <mat-select-trigger>
            {{optionSelector.value?.name}}
          </mat-select-trigger>
          <mat-option *ngFor="let option of fulfillmentOptions" [value]="option">
            {{(option.type === 'shipping' ? 'shipping.shipping' : 'shipping.pickup') | translate}} -
            {{option.name}}
            {{option.type === 'shipping' ? ' - ' + (option.shippingCharge | currency : currency : 'symbol' : '1.2-2') + ' ' + currency : ''}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span *ngIf="fulfillmentOption?.type === 'shipping'">
        {{'shipping.shippingCharge' | translate}}: {{fulfillmentOption?.shippingCharge | currency : currency : 'symbol' : '1.2-2'}} {{ currency }}
      </span>
      <span *ngIf="fulfillmentOption?.type === 'pickup'">
        {{fulfillmentOption?.description}}
      </span>
    </div>
    <div class="column-container" *ngIf="fulfillmentOption?.type === 'shipping' && addressSelected && !showAddressSelector">
      <span>{{addressSelected.name}}</span>
      <span>{{addressSelected.address1}}{{addressSelected.address2 ? ('  ' + addressSelected.address2) : ''}}</span>
      <span>{{addressSelected.city}}, {{addressSelected.state}} {{addressSelected.zip}}</span>
    </div>
  </div>
  <!-- Address Selector -->
  <div *ngIf="!showNewAddressComponent && fulfillmentOption?.type === 'shipping' && showAddressSelector">
    <mat-form-field appearance="outline">
      <mat-label>{{'shipping.selectAddress' | translate}}</mat-label>
      <mat-select (ngModelChange)="onAddressSelected($event)" [(ngModel)]="addressSelected">
        <mat-option *ngFor="let option of addresses" [value]="option">{{
          option.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Section to add a new address -->
  <shipping-form
    *ngIf="fulfillmentOption?.type === 'shipping' && showNewAddressComponent"
    section="new-item-section"
    [title]="'shipping.changeShippingAddress' | translate"
    (onCancel)="onAddressCreated(false)"
    (onSave)="onAddressCreated(true)"
  ></shipping-form>
  <div class="buttons-container" *ngIf="showButtons">
    <button
      class="full-container-button"
      *ngIf="fulfillmentOption?.type === 'shipping'"
      mat-stroked-button
      (click)="changeAddress()"
    >
      {{ 'shipping.changeAddress' | translate | uppercase }}
    </button>
    <button
      *ngIf="requireFulfillmentMethod"
      mat-stroked-button
      (click)="editState.emit('cancel')"
    >
      {{ 'general.cancel' | translate | uppercase }}
    </button>
    <button
      mat-raised-button
      [ngClass]="{'full-container-button': !requireFulfillmentMethod}"
      color="primary"
      [disabled]="!validateMethod()"
      (click)="confirmFulfillmentMethod()"
    >
      {{ 'general.confirm' | translate | uppercase }}
    </button>
  </div>
</section>
