import { Component, Input } from '@angular/core';
import { BuynowItem, ItemState, ItemType, TicketItem } from '../../../types';

@Component({
  selector: 'card-buy-detail',
  templateUrl: './card-buy-detail.component.html',
  styleUrls: ['./card-buy-detail.component.scss'],
})
export class CardBuyDetailComponent {
  _item?: TicketItem | BuynowItem;
  countdown?: string;
  title = '';
  itemsLeft = 0;

  @Input()
  set item(item: TicketItem | BuynowItem | undefined) {
    this._item = item;
    this.updateValues();
  }
  get item() {
    return this._item;
  }

  private updateValues() {
    if (!this.item) {
      return;
    }
    this.title = '';
    this.itemsLeft = 0;
    const itemsLeft = this.item.maxItems - (this.item.purchasedCount || 0);
    const title =
      this.item.itemType === ItemType.Ticket
        ? 'event.ticketsAvailable'
        : 'event.itemsLeft';
    if (this.item.itemState === ItemState.Pending) {
      this.title = title;
      this.itemsLeft = itemsLeft;
    } else if (this.item.itemState === ItemState.Open) {
      this.title = itemsLeft > 0 ? title : 'modal.soldOut';
      this.itemsLeft = itemsLeft > 0 ? itemsLeft : 0;
    }
  }
}
