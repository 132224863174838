import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type ConfirmDialogData = {
  confirmKey?: string;
  confirmText?: string;
  bodyKey?: string;
  okKey?: string;
  hideCancel?: boolean;
};

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  confirmKey = this.dialogData.confirmKey ?? 'GENERAL.confirmMessage';
  confirmText = this.dialogData.confirmText;
  hideCancel = this.dialogData.hideCancel ?? false;
  okKey = this.dialogData.okKey ?? 'GENERAL.yes';
  bodyKey = this.dialogData.bodyKey;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogData) {
  }
}
