import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuctionItem } from '@fan-types/item.model';

export interface BidConfirmDialogData {
  bid: number;
  item: AuctionItem;
}

@Component({
  selector: 'bid-confirm-dialog',
  templateUrl: './bid-confirm-dialog.component.html',
  styleUrls: ['./bid-confirm-dialog.component.scss']
})
export class BidConfirmDialogComponent {

  bid: number;
  item: AuctionItem;


  constructor(
    public dialogRef: MatDialogRef<BidConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: BidConfirmDialogData,
  ) {
    this.bid = dialogData.bid;
    this.item = dialogData.item;

  }

  cancel() {
    this.dialogRef.close();
  }

  bidConfirmed() {
    this.dialogRef.close(this.bid);
  }
}
