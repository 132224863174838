import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { buildItemPath } from '../../../helpers/build-item-path';
import { LayoutTypeService } from '../layout-type.service';

@Component({
  selector: 'card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss'],
})
export class CardLayoutComponent {
  @Output() clicked = new EventEmitter<void>();
  @Input() title?: string;
  @Input() titleNote?: string;
  @Input() description?: string;
  @Input() cta?: string;
  @Input() chip?: string;
  @Input() warningChip?: boolean;
  @Input() link?: string;
  @Input() path?: string[];
  @Input() pathAbsolute = false;
  @Input() image?: string;
  @Input() overlayImage?: string;
  @Input() wrap?: boolean = false;
  @Input() teamName?: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public ls: LayoutTypeService
  ) {}

  onClick() {
    if (this.pathAbsolute && this.path) {
      this.router.navigate(this.path);
    } else if (this.path) {
      const { routes } = buildItemPath(
        this.route.snapshot.data,
        this.route.snapshot.params
      );
      this.router.navigate([...routes, ...this.path]);
    }
    this.clicked.emit();
  }
}
