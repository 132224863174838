import { Component, Input } from '@angular/core';
import {
  TicketItem,
  BuynowItem,
  Auction,
  AuctionItem,
  Item,
  GiveawayItem,
  DonationItem,
} from '../../../types';

@Component({
  selector: 'cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
})
export class CtaComponent {
  @Input() item?: Item;
  @Input() auction?: Auction | undefined | null;

  asAuction(item: Item | undefined) {
    return item as AuctionItem;
  }
  asGiveaway(item: Item | undefined) {
    return item as GiveawayItem;
  }
  asBin(item: Item | undefined) {
    return item as TicketItem | BuynowItem;
  }
  asDonation(item: Item | undefined) {
    return item as DonationItem;
  }
}
