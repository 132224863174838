export enum AnalyticsEvents {
  BidStart = 'BidStart',
  BidSuccess = 'BidSuccess',
  EnterGiveaway = 'EnterGiveaway',
  OvertimeBidStart = 'OvertimeBidStart',
  OvertimeBidSuccess = 'OvertimeBidSuccess',
  AuctionItemPaid = 'AuctionItemPaid',
  StartPurchase = 'StartPurchase',
  OvertimeViewAuctionItemDetail = 'OvertimeViewAuctionItemDetail',
  ViewFeaturedItem = 'ViewFeaturedItem',
  ClickItemFromEventDetail = 'ClickItemFromEventDetail',
  ClickRelatedItem = 'ClickRelatedItem',
  // View from Router
  ViewAuctionItems = 'ViewAuctionItems',
  ViewAuctionItemDetail = 'ViewAuctionItemDetail',
  PrivacyPolicyViewed = 'PrivacyPolicyViewed',
  HelpViewed = 'HelpViewed',
  TermsViewed = 'TermsViewed',
  HomeViewed = 'HomeViewed',
  ClosedEventsViewed = 'ClosedEventsViewed',
  TeamClosedEventsViewed = 'TeamClosedEventsViewed',
  TeamPageViewed = 'TeamPageViewed',
  LockerViewed = 'LockerViewed',
  ShippingViewed = 'ShippingViewed',
  AccountViewed = 'AccountViewed',
  FavesViewed = 'FavesViewed',
  PaymentsViewed = 'PaymentsViewed',
  RegisterViewed = 'RegisterViewed',
  LoginViewed = 'LoginViewed',
  ForgotViewed = 'ForgotViewed',
  Donate = 'Donate',
  StartDonate = 'StartDonate',
  SearchCancelled = 'SearchCancelled',
  SearchResultSelected = 'SearchResultSelected',
}
export enum AnalyticsIncrementals {
  Bids = 'Bids',
  Purchases = 'Purchases',
  Giveaway = 'Giveaway',
}
