import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bid-complete-dialog',
  templateUrl: './bid-complete-dialog.component.html',
  styleUrls: ['./bid-complete-dialog.component.scss']
})
export class BidCompleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BidCompleteDialogComponent>
  ) {

  }

  close() {
    this.dialogRef.close();
  }
}
