import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../store';
import {
  pullCCards,
  requestDeleteCCard,
} from '../../../store/ccard/ccard.actions';
import {
  selectAll,
  selectCCardByCurrency,
  selectCCardByCurrencyAndFoundation,
} from '../../../store/ccard/ccard.selectors';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import { MatDialog } from '@angular/material/dialog';
import { CCard, Currency } from '../../../types';
import {
  PaymentEditComponent,
  PaymentEditData,
} from '../payment-edit/payment-edit.component';
import {
  PaymentDialogAddComponent,
  PaymentAddData,
} from '../payment-dialog-add/payment-dialog-add.component';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../../../config/app';

@Component({
  selector: 'payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
})
export class PaymentsListComponent implements OnInit {
  @Input() currency?: Currency;
  @Input() foundationId?: string;
  @Input() selectable?: boolean;
  @Input() skipLoad?: boolean;
  @Input() canAddCard = true;
  @Input() hideFoundations = true;
  @Output() cardSelected = new EventEmitter<CCard>();
  canEditCard = APP_CONFIG.canEditCard;

  cards$?: Observable<CCard[]>;
  constructor(private store: Store<GlobalState>, private dialog: MatDialog) {}

  ngOnInit(): void {
    const selector = (() => {
      if (this.currency && this.foundationId)
        return selectCCardByCurrencyAndFoundation(
          this.currency,
          this.foundationId
        );
      if (this.currency) return selectCCardByCurrency(this.currency);
      return selectAll;
    })();
    this.cards$ = this.store.select(selector);
    if (!this.skipLoad)
      this.store.dispatch(
        pullCCards({ currency: this.currency, foundationId: this.foundationId })
      );
  }

  edit(card: CCard) {
    this.dialog.open<PaymentEditComponent, PaymentEditData>(
      PaymentEditComponent,
      {
        id: 'edit-card',
        data: {
          cardId: card.id,
          currency: card.currency,
        },
      }
    );
  }

  add() {
    this.dialog.open<PaymentDialogAddComponent, PaymentAddData>(
      PaymentDialogAddComponent,
      {
        id: 'add-card',
        data: {
          currency: this.currency,
          foundationId: this.foundationId,
        },
      }
    );
  }

  delete(card: CCard) {
    const data: ConfirmDialogData = {
      confirmKey: 'billing.deleteCard',
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.store.dispatch(
          requestDeleteCCard({ cardId: card.id, currency: card.currency })
        );
    });
  }
}
