import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LetDirective, PushPipe } from '@ngrx/component';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireMessagingModule,
  VAPID_KEY,
} from '@angular/fire/compat/messaging';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './store/state';
import { EffectsModule } from '@ngrx/effects';
import { ContentEffects } from '@fan-store/content/content.effects';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AccessTokenInterceptorService } from '@fan-services/access-token-interceptor.service';
import { environment } from '@fan-env/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EventsModule } from '@fan-events/events.module';
import { SharedModule } from '@fan-shared/shared.module';
import { HeaderComponent } from './nav/header/header.component';
import { FooterComponent } from './nav/footer/footer.component';
import { LoadingComponent } from './nav/loading/loading.component';
import { IntegrationsComponent } from './nav/integrations/integrations.component';
import { TranslateComponent } from './nav/integrations/translate/translate.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuctionEffects } from './store/auction/auction.effects';
import { AuctionItemsEffects } from './store/auction-items/auction-items.effects';
import { ItemEffects } from './store/item/item.effects';
import { ProfileModule } from './profile/profile.module';
import { SignModule } from './sign/sign.module';
import { UserEffects } from './store/user/user.effects';
import { ContentModule } from './content/content.module';
import { CCardEffects } from './store/ccard/ccard.effects';
import { BidEffects } from './store/bid/bid.effects';
import { LockerEffects } from './store/locker/locker.effects';
import * as Sentry from '@sentry/angular-ivy';

import { RelatedItemsEffects } from './store/related-items/related-items.effects';
import { OvertimeModule } from './shared/overtime/overtime.module';
import { SearchInputComponent } from './nav/header/search-input/search-input.component';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `./assets/i18n-13/${environment.appId}/`,
    '.json'
  );
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    IntegrationsComponent,
    TranslateComponent,
    SearchInputComponent,
  ],
  imports: [
    MatToolbarModule,
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {}),
    EffectsModule.forRoot([
      ContentEffects,
      AuctionEffects,
      AuctionItemsEffects,
      RelatedItemsEffects,
      ItemEffects,
      UserEffects,
      CCardEffects,
      BidEffects,
      LockerEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    LetDirective,
    PushPipe,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    OvertimeModule,
    EventsModule,
    ContentModule,
    ProfileModule,
    SignModule,
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptorService,
      multi: true,
    },
    { provide: VAPID_KEY, useValue: environment.firebase.vapidKey },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
