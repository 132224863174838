import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../../store';
import { selectProfile } from '../../../../store/user/user.selectors';
import { AuctionItem, ItemState } from '../../../../types';

@Component({
  selector: 'card-auction-detail',
  templateUrl: './card-auction-detail.component.html',
  styleUrls: ['./card-auction-detail.component.scss'],
})
export class CardAuctionDetailComponent {
  _item?: AuctionItem;
  countdown?: string;
  profile$ = this.store.select(selectProfile);
  bidsCount = 0;
  showAmount = false;
  amount = 0;

  @Input()
  set item(item: AuctionItem | undefined) {
    this._item = item;
    this.updateValues();
  }
  get item() {
    return this._item;
  }
  title = '';

  constructor(private store: Store<GlobalState>) {}

  onClick(item: AuctionItem | undefined) {
    // TODO: click auction
    console.log(item);
  }

  private updateValues() {
    if (!this.item) {
      return;
    }
    this.title = '';
    this.showAmount = false;
    this.amount = 0;
    this.bidsCount = 0;
    if (this.item.itemState === ItemState.Pending) {
      this.title = 'event.startingBid';
      this.showAmount = true;
      this.amount = this.item.minimumBid;
    } else if (this.item.itemState === ItemState.Open) {
      this.title = 'event.currentBid';
      this.bidsCount = this.item.bidCount;
      this.showAmount = true;
      this.amount = this.item.topBidAmount;
    } else if (this.item.itemState === ItemState.Overtime) {
      this.title = 'event.otStartingBid';
      this.showAmount = true;
      this.amount = this.item.topBidAmount;
    } else if (
      [
        ItemState.Closed,
        ItemState.Closing,
        ItemState.Paid,
        ItemState.Sold,
      ].includes(this.item.itemState)
    ) {
      this.title = 'event.winningBid';
      this.showAmount = true;
      this.amount = this.item.topBidAmount ?? 0;
    }
  }
}
