<ng-container *ngrxLet="item$ as item">
  <container-element [ngSwitch]="item?.itemType">
    <card-auction *ngSwitchCase="'auction'" [item]="asAuction(item)">
      <ng-container *ngrxLet="profile$ as profile">
        <ng-container
          *ngIf="asAuction(item)?.topBidUserProfileId !== profile?.id"
        >
          <div class="mat-h5 dark" *ngIf="item?.itemState === 'open'">
            {{ 'event.outbid' | translate }}
          </div>
          <div
            class="dark"
            *ngIf="item?.itemState === 'closed' || item?.itemState === 'paid'"
          >
            {{ 'event.closedHint' | translate }}
          </div>
        </ng-container>
      </ng-container>
    </card-auction>
    <card-bin *ngSwitchCase="'buynow'" [item]="asBin(item)">
      <div class="mat-h5 dark" *ngIf="purchaseCount">
        {{
          'event.purchasedTotal'
            | translate
              : {
                  total: purchaseCount,
                  plural: (purchaseCount > 1 && 's') || ''
                }
        }}
      </div>
    </card-bin>
    <card-raffle *ngSwitchCase="'ticket'" [item]="asTicket(item)">
      <div class="mat-h5 dark" *ngIf="purchaseCount">
        {{
          'event.purchasedTotal'
            | translate
              : {
                  total: purchaseCount,
                  plural: (purchaseCount > 1 && 's') || ''
                }
        }}
      </div>
    </card-raffle>
    <card-giveaway
      *ngSwitchCase="'giveaway'"
      [item]="asGiveaway(item)"
    ></card-giveaway>
    <card-donation *ngSwitchCase="'donation'" [item]="asDonation(item)">
      <div class="mat-h5 dark" *ngIf="purchaseCount">
        {{'event.donatedTotal'  | translate}}<span>{{ purchaseCount | currency: 'USD':'symbol':'1.2-2' }}</span>
      </div>
      <div class="mat-h5 dark" *ngIf="purchaseCount">
        {{'event.donatedMessage'  | translate}}
      </div>
    </card-donation>
  </container-element>
</ng-container>
