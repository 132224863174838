import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../store';
import { Auction, Item } from '../../types';
import * as ItemActions from '@fan-store/item/item.actions';
import { selectById } from '../../store/item/item.reducer';
import { selectEventByItemId } from '../../store/selectors';

@Component({
  selector: 'item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent {
  item$?: Observable<Item | undefined>;
  auction$?: Observable<Auction | undefined>;
  _itemId: string | undefined;

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    if (this.itemId)
      this.store.dispatch(ItemActions.pullItem({ itemId: this.itemId }));
  }

  set itemId(itemId: string | undefined) {
    this._itemId = itemId;
    if (itemId) {
      this.item$ = this.store.select(selectById(itemId));
      this.auction$ = this.store.select(selectEventByItemId(itemId));
      this.store.dispatch(ItemActions.pullItem({ itemId }));
    }
  }
  get itemId() {
    return this._itemId;
  }

  constructor(
    private store: Store<GlobalState>,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => (this.itemId = params.id));
  }
}
