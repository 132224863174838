import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../../store';
import { selectById } from '../../../store/item/item.reducer';
import {
  Auction,
  AuctionItem,
  AuctionState,
  BuynowItem,
  DonationItem,
  GiveawayItem,
  Item,
  TicketItem,
} from '../../../types';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent {
  item$?: Observable<Item | undefined>;
  @Output() clicked = new EventEmitter<Item>();

  @Input()
  auction?: Auction;

  @Input()
  set id(id: string | undefined) {
    if (!id) return;
    this.item$ = this.store.select(selectById(id));
  }
  constructor(
    private store: Store<GlobalState>,
    private locationService: LocationService
  ) {}

  setFoundationToItem(item: any) {
    const localItem = { ...item };
    if (this.auction && item) {
      localItem.foundation = this.auction.foundation;
    }
    return localItem;
  }

  asGiveaway(item: Item | undefined) {
    return this.setFoundationToItem(item) as GiveawayItem;
  }

  asTicket(item: Item | undefined) {
    return this.setFoundationToItem(item) as TicketItem;
  }

  asBin(item: Item | undefined) {
    return this.setFoundationToItem(item) as BuynowItem;
  }

  asAuction(item: Item | undefined) {
    return this.setFoundationToItem(item) as AuctionItem;
  }

  asDonation(item: Item | undefined) {
    return this.setFoundationToItem(item) as DonationItem;
  }

  onClick(item: Item | undefined) {
    if (item) this.clicked.emit(item);
    if (item?.auction?.auctionState === AuctionState.Open) {
      this.locationService.logLocation();
    }
  }
}
