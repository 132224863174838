<mat-dialog-content>
  <div class="bid-confirm-container">
    <span class="mat-h5 dialog-title">{{'bid.readyToBid' | translate}}</span>
    <div class="dialog-description">
      <span class="mat-caption">{{'bid.confirmDialogDesc' | translate}}</span>
    </div>
    <span class="mat-h6 item-details">{{item.title}}</span>
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>attach_money</mat-icon>
      <input
        cdkFocusInitial
        matInput
        type="number"
        [(ngModel)]="bid"
        name="bidAmount"
      />
    </mat-form-field>
    <div class="dialog-actions">
      <button
        mat-stroked-button
        (click)="cancel()"
      >
        {{ 'general.cancel' | translate | uppercase }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="bidConfirmed()"
      >
        {{ 'general.continue' | translate | uppercase }}
      </button>
    </div>
  </div>
</mat-dialog-content>
