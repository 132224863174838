<mat-dialog-content>
  <div class="bid-complete-container">
    <span class="mat-h5 dialog-title">{{'bid.complete' | translate}}</span>
    <div class="divider"></div>
    <span class="text-bold">{{'bid.completeShipInfo' | translate}}</span>
    <button
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        {{'modal.ok' | translate}}
      </button>
  </div>
</mat-dialog-content>
