<mat-dialog-content>
  <span *ngIf="!confirmText" class="mat-h6" [translate]="confirmKey"></span>
  <span *ngIf="confirmText" class="mat-h6">{{ confirmText }}</span>
  <p *ngIf="bodyKey">{{ bodyKey | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="!hideCancel"
    mat-stroked-button
    color="accent"
    mat-dialog-close
  >
    {{ 'GENERAL.no' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    {{ okKey | translate }}
  </button>
</mat-dialog-actions>
